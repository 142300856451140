  
  .content {
    transform: translate(-50%, -50%);
    height: 180px;
    font-size: 30px;
    line-height: 40px;
    color: black;
    position: absolute;
    left: 50%;
  }
  .content__container {
    font-weight: 600;
    overflow: hidden;
    height: 50px;
    padding: 0 30px;
  }
  .content__container:before {
    content: "{";
    left: 0;
  }
  .content__container:after {
    content: "}";
    right: 0;
  }
  .content__container:after, .content__container:before {
    position: absolute;
    top: 0;
    color: #16a085;
    font-size: 42px;
    line-height: 40px;
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .content__container__text {
    display: inline;
    float: left;
    margin: 0;
    width: max-content !important;
  }
  .content__container__list {
    margin-top: 0;
    padding-left: 170px;
    text-align: left;
    list-style: none;
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
  }
  .content__container__list__item {
    line-height: 45px;
    margin: 0;
  }
  .content__container__list__item::after {
      content: " ?";
  }
  @-webkit-keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @-webkit-keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
  }
  @keyframes opacity {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes change {
    0%, 12.66%, 100% {
      transform: translate3d(0, 0, 0);
    }
    16.66%, 29.32% {
      transform: translate3d(0, -25%, 0);
    }
    33.32%, 45.98% {
      transform: translate3d(0, -50%, 0);
    }
    49.98%, 62.64% {
      transform: translate3d(0, -75%, 0);
    }
    66.64%, 79.3% {
      transform: translate3d(0, -50%, 0);
    }
    83.3%, 95.96% {
      transform: translate3d(0, -25%, 0);
    }
  }