@import url('https://fonts.googleapis.com/css2?family=Biryani:wght@600;900&family=Varela+Round&display=swap');

* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Varela Round', sans-serif;
  width: 100%;
  height: 100%;
  
}

body::-webkit-scrollbar {
  display: none;
}

p {
  margin-bottom: 0 !important;
  padding-bottom: 0;
}

:root {
  --text: white;
  --green: #00ff85;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #0A2129;
}

.branding-text {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  text-shadow: 0 0 1px #fff, 0 0 1.5px #fff, 0 0 2px #fff, 0 0 1.5px #04ff04;
}