@media only screen and (min-width: 100px) and (max-width: 1024px) {
    .navbar {
        padding-top: 10px !important;
        justify-content: center !important;
    }
    .hero {
        flex-direction: column-reverse;
    }
    .hero-left, .hero-right {
        width: 100%;
        text-align: center;
    }
    .hero-left {
        align-items: start;
    }
    .glitch {
        font-size: 36px;
    }
    .desp-text {
        font-size: 18px;
    }
    .card {
        margin-bottom: 30px;
    }
    .content {
        font-size: 20px !important;
    }
    .content__container__text {
        word-spacing: 6px;
    }
    .content__container__list {
        padding-left: 120px !important;
    }
    .content__container__list__item::after {
        content: "" !important;
    }
    .wi-left, .wi-right {
        width: 100%;
        margin-top: 30px;
        padding-left: 0;
        text-align: center;
    }
    .sub-header {
        font-size: 56px;
    }
    .desp {
        padding: 0 30px
    }
    .uni-left, .uni-right {
        width: 100%;
    }
    .p3 {
        margin-bottom: 0;
    }
    .uni-header {
        font-size: 36px;
    }
    .uni-des {
        padding: 0 40px;
    }
    .p5 {
        margin: 30px;
    }
}

/* Tablet */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .p1 {
        height: 1000px;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
    .navbar {
        margin-bottom: 100px;
    }
}