.card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    position: relative;
    width: 280px;
    height: 420px;
    border: 0 !important;
    margin-top: 40px;
}

.card-margin {
    margin: 0 10px;
    margin-top: 40px;
}

.card:before {
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
    border: 0px;
}

.card__background {
    background-image: url(https://cappriciosec.com/images/bg.gif);
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    bottom: 0;
    filter: brightness(0.50) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition:
        filter 200ms linear,
        transform 200ms linear;
}

.card:hover .card__background {
    transform: scale(1.05) translateZ(0);
}

.card-container:hover>.card:not(:hover) .card__background {
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(5px);
}

.card__content {
    left: 0;
    padding: 10px;
    position: absolute;
    top: 0;
}

.card__category {
    color: #00ff85;
    font-size: 0.9rem;
    font-family: open sans;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.card__heading {
    color: white;
    font-family: open sans;
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    line-height: 1.4;
    word-spacing: 100vw;
    text-transform: capitalize;
}