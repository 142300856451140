/* Demo assets */

    .client-logo {
        object-fit: contain;
        /* filter: saturate(0%); */
    }

  .client-header {
    font-size: 36px;
    text-align: center;
    margin: 30px 0;
    margin-bottom: 0;
  }

  .client-header::after {
    font-family: 'Varela Round', sans-serif;
    font-weight: 700;
    content: " Secured";
    color: #00ff84;
  }

.touch .client-close.click-on {
    pointer-events: auto;
    z-index: 6;
}

.toggle-container {
    text-align: center;
}

.toggle-wrap {
    margin: 0 auto 20px;
    display: inline-block;
    clear: both;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    font-weight: bolder;
}

.toggle {
    margin-right: 8px;
}

.touch div.minus.client-more-less::after {
    background-color: rgba(255, 255, 255, 0);
}

.toggle-switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    cursor: pointer;
    padding: 3px;
    border-radius: 25px;
    width: 42px;
    background-color: rgb(122, 212, 68);
    color: rgb(225, 225, 225);
    -webkit-transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -ms-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
    transition: 0.4s ease all;
}

.switch {
    float: right;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
}

.switched-off {
    background-color: rgb(200, 200, 200);
}

.switched-off .switch {
    margin-right: 15px;
}

.switch {
    -webkit-transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -ms-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
}

/* End Demo Assets */



* {
    box-sizing: border-box;
}

body {
    font-family: Sans-serif;
}



.client-wrap {
    margin: 5% auto 0;
    margin-top: 0;
    padding: 0;
    max-width: 1280px;
    width: 100%;
}


.client {
    vertical-align: middle;
    position: relative;
    margin: 5px;
    max-width: calc(20% - 20px);
    height: 100%;
    width: 100%;
    list-style-type: none;
    display: inline-block;
    text-align: center;
    transition: 0.2s ease all;
}

.no-touch .client:hover,
.no-touch .opacity-focus .client:hover {
    opacity: 1;
    z-index: 2;
}



.client:hover .overflow-wrapper {
    z-index: 5;
    left: 0;
}



.client-meta {
    position: absolute;
    display: block;
    visibility: hidden;
    overflow: hidden;
    text-align: center;
    border-radius: 4px;
    
    width: 100%;
    min-height: 200px;
    background-color: rgb(255, 255, 255);
    text-align: left;
    border: 1px solid rgb(228, 228, 228);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -ms-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
}

.touch .client-more-less,
.touch .client-close {
    z-index: 2;
    position: absolute;
    left: calc(50% - 10px);
    text-align: center;
    top: 15px;
    height: 26px;
    width: 26px;
    text-align: center;
    border-radius: 50%;
    line-height: 1.3;
    font-weight: 600;
    transition: 0.2s ease all;
}

.touch .client.reset .client-meta {
    opacity: 0;
    visibility: hidden;
}

.touch .client-more-less {
    display: inline-block;
    pointer-events: none;
    z-index: 5;
    background-color: rgb(234, 236, 236);
    color: rgba(0, 0, 0, 0.5);
}

.touch .client-close {
    z-index: 6;
    background-color: transparent;
}

.client-close:hover~.client-meta {
    
    visibility: hidden;
}

.client-close:hover~img.client-logo,
.client-more-less~img.client-logo {
    width: 80%;
}

.touch .client-more-less::before,
.touch .client-more-less::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.25);
    padding: 0;
    display: block;
    border-radius: 3px;
    position: absolute;
    margin: 0 auto;
}

.touch .client-more-less::before {
    margin: 11px 6px;
    width: 14px;
    height: 4px;
}

.touch .client-more-less::after {
    margin: 6px 11px;
    width: 4px;
    height: 14px;
}

/* Responsive Reset */


.reset .client-logo {
    width: 80% !important;
}


/* End Responsive Reset */

.client:hover .client-logo {
    width: 76%;
}

img.reset-logo {
    width: 80%;
}

.project-list:hover .overflow-wrapper img,
.opaque {
    opacity: 1;
}

.overflow-wrapper {
    position: relative;
    padding-top: calc(100% - 45px);
    width: 100%;
    display: inline-block;
    z-index: 3;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    transition: 0.2s ease all;
}

.overflow-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
    padding: 1px;
    border-radius: 4px 4px 0 0;
    -webkit-transition: 0.15s ease all;
    -moz-transition: 0.15s ease all;
    -ms-transition: 0.15s ease all;
    -o-transition: 0.15s ease all;
    transition: 0.15s ease all;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
}

.client-logo {
    z-index: 1;
    position: absolute;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    top: 50%;
    width: 80%;
    height: auto;
    transition: 0.2s ease all;
    -webkit-animation: fadein 0.8s;
    -moz-animation: fadein 0.8s;
    -ms-animation: fadein 0.8s;
    -o-animation: fadein 0.8s;
    animation: fadein 0.8s;
}

.white-bg {
    background-color: rgb(255, 255, 255);
}

.project-list {
    overflow: hidden;
    max-height: 230px;
    margin-top: calc(100% - 44px);
    padding: 0;
    margin-bottom: 0;
    line-height: 1;
    padding-top: 0;
    padding-bottom: 20px;
    text-align: center;
    border-bottom: 4px solid rgb(189, 189, 255);
    transition: 0.2s ease all;
}

.project-list:hover {
    max-height: 270px;
}

.project-list:hover .bar {
    max-width: 100%;
    border-top: 4px solid rgb(189, 189, 255);
}

.project-list:hover {
    border-bottom: 4px solid rgb(255, 255, 255);
}

.bar {
    margin: 0 auto;
    border: 0;
    border-top: 4px solid rgb(232, 232, 232);
    max-width: calc(100% - 30px);
    transition: 0.2s ease all;
}

.project-list li {
    cursor: pointer;
    display: block;
    text-align: center;
    padding: 8px 10px;
    font-size: 0.9rem;
}

.project-list li:hover {
    color: rgb(100, 100, 100);
}

/* Sizes for demo */
.square {
    padding: 20%;
}

.smaller {
    padding: 10%;
}

/* End Sizes for demo */

@media screen and (max-width: 1024px) {
    .client {
        max-width: calc(25% - 30px);
    }
}

@media screen and (max-width: 820px) {
    .client {
        max-width: calc(33.33% - 30px);
    }
}

@media screen and (max-width: 620px) {
    .client {
        max-width: calc(50% - 30px);
    }
}

@media screen and (max-width: 525px) {
    .client {
        float: none;
        max-width: 260px;
        width: 90%;
    }

    .client-wrap {
        text-align: center;
    }
}

/* Animation */


