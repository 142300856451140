.glitch {
  color: var(--text);
  font-size: 60px;
  position: relative;
  font-weight: lighter;
}

.glitch:after {
  content: "A future to cyber era";
  position: absolute;
  left: -2px;
  text-shadow: -1px 0 var(--green);
  top: 0;
  color:  var(--green);
  background: none;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

.glitch:before {
  content: "A future to cyber era";
  position: absolute;
  left: -2px;
  text-shadow: 1px 0  var(--green);
  top: 0;
  color: var(--green);
  background: none;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

@keyframes noise-anim {
  0% {
    clip: rect(45px, 9999px, 34px, 0);
  }
  5% {
    clip: rect(67px, 9999px, 57px, 0);
  }
  10% {
    clip: rect(7px, 9999px, 32px, 0);
  }
  15.0% {
    clip: rect(53px, 9999px, 61px, 0);
  }
  20% {
    clip: rect(21px, 9999px, 49px, 0);
  }
  25% {
    clip: rect(8px, 9999px, 33px, 0);
  }
  30.0% {
    clip: rect(17px, 9999px, 36px, 0);
  }
  35% {
    clip: rect(23px, 9999px, 1px, 0);
  }
  40% {
    clip: rect(98px, 9999px, 86px, 0);
  }
  45% {
    clip: rect(64px, 9999px, 72px, 0);
  }
  50% {
    clip: rect(42px, 9999px, 60px, 0);
  }
  55.0% {
    clip: rect(38px, 9999px, 46px, 0);
  }
  60.0% {
    clip: rect(21px, 9999px, 55px, 0);
  }
  65% {
    clip: rect(71px, 9999px, 57px, 0);
  }
  70% {
    clip: rect(34px, 9999px, 70px, 0);
  }
  75% {
    clip: rect(60px, 9999px, 67px, 0);
  }
  80% {
    clip: rect(51px, 9999px, 48px, 0);
  }
  85.0% {
    clip: rect(72px, 9999px, 48px, 0);
  }
  90% {
    clip: rect(95px, 9999px, 98px, 0);
  }
  95% {
    clip: rect(52px, 9999px, 65px, 0);
  }
  100% {
    clip: rect(87px, 9999px, 50px, 0);
  }
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(93px, 9999px, 57px, 0);
  }
  5% {
    clip: rect(22px, 9999px, 5px, 0);
  }
  10% {
    clip: rect(54px, 9999px, 44px, 0);
  }
  15.0% {
    clip: rect(24px, 9999px, 40px, 0);
  }
  20% {
    clip: rect(88px, 9999px, 85px, 0);
  }
  25% {
    clip: rect(95px, 9999px, 86px, 0);
  }
  30.0% {
    clip: rect(62px, 9999px, 6px, 0);
  }
  35% {
    clip: rect(64px, 9999px, 39px, 0);
  }
  40% {
    clip: rect(2px, 9999px, 85px, 0);
  }
  45% {
    clip: rect(94px, 9999px, 58px, 0);
  }
  50% {
    clip: rect(46px, 9999px, 8px, 0);
  }
  55.0% {
    clip: rect(65px, 9999px, 52px, 0);
  }
  60.0% {
    clip: rect(7px, 9999px, 59px, 0);
  }
  65% {
    clip: rect(85px, 9999px, 75px, 0);
  }
  70% {
    clip: rect(40px, 9999px, 19px, 0);
  }
  75% {
    clip: rect(53px, 9999px, 54px, 0);
  }
  80% {
    clip: rect(17px, 9999px, 37px, 0);
  }
  85.0% {
    clip: rect(87px, 9999px, 59px, 0);
  }
  90% {
    clip: rect(37px, 9999px, 77px, 0);
  }
  95% {
    clip: rect(36px, 9999px, 53px, 0);
  }
  100% {
    clip: rect(80px, 9999px, 94px, 0);
  }
}
