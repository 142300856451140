.p1 {
    width: 100%;
    height: 800px;
    background-color: #0A2129;
}

.navbar {
    height: 70px;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.nav-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logo {
    width: 70px;
    height: 70px;
}

.logo-text {
    color: var(--text);
    margin-top: 10px;
    margin-left: 4px;
    font-weight: lighter;
}

.logo-text::after {
    content: ".";
    color: var(--green);
}

.hero {
    display: flex;
    flex-direction: row;
    height: 80%;
}

.hero-left {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-right {
    width: 50%;
}

.desp-text {
    font-size: 28px;
    color: var(--text);
}

.p2 {
    margin-top: 20px;
    padding: 20px
}

.p3 {
    margin-top: 40px;
}

.p3-context {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-items: center;
}

.text-spinner-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 140px;
}

.wi-left, .wi-right {
    width: 50%;
}

.wi-left {
    padding-left: 60px;
}

.wi-left-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grd-header-wrapper {
    background: #FDC830;  /* fallback for old browsers */
    background: linear-gradient(to right, #F37335, #FDC830); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: -webkit-linear-gradient(to right, #F37335, #FDC830);  /* Chrome 10-25, Safari 5.1-6 */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header {
    font-size: 36px;
    font-family: 'Varela Round', sans-serif;
}

.grd-sub-header-wrapper {
    background: #4568dc; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4568dc, #b06ab3); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4568dc, #b06ab3);    
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sub-header {
    font-size: 80px;
    font-family: 'Varela Round', sans-serif;
}

.desp {
    font-size: 18px;
    font-family: 'Varela Round', sans-serif;
}

.km-btn {
    position: relative;
    width: 140px;
    height: 50px;
    background: #0A2129;
    border: 1px solid #00ff85;
    margin: 40px 20px;
    padding: 5px 27px;
    border-radius: 1em;
    cursor: pointer;
    color: #00ff85;
    font-weight: 500;
    transition: .3s;
    text-transform: uppercase;
    font-size: 12px;
    overflow: hidden;
    outline: none;
    font-family: 'Varela Round', sans-serif;
}

.km-btn:before,
.km-btn:after {
    content: "";
    position: absolute;
    height: 25px;
    width: 20px;
    background: #00ff85;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.km-btn:before {
    left: -20px;
}

.km-btn:after {
    right: -20px;
}

.km-btn:hover {
    box-shadow: 0 0 25px #00ff85, 0 1px 5px #0A2129;
    border-color: #00ff85;
    background: #00ff85;
    color: #0A2129;

}

.km-btn:hover:before {
    animation: leftbox 1s forwards;
}

.km-btn:hover:after {
    animation: rightbox 1s forwards;
}

.university-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.uni-left, .uni-right {
    width: 50%;
}

.uni-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.uni-header {
    color:#0A2129;
    font-size: 56px;
    font-weight: 700;
}

.uni-header::after {
    content: " University";
    color: #00ff85;
    font-family: 'Varela Round', sans-serif;
}

.uni-des {
    padding: 5px;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Varela Round', sans-serif;
}

.uni-course {
    margin: 10px;
}

.p5 {
    margin: 80px 0;
}

.p5-header {
    font-size: 30px;
    text-align: center;
    font-family: 'Biryani', sans-serif;
    color: rgba(0, 0, 0, 0.808);
}

.p5-header::after {
    content: " Cappricio";
    font-size: 56px;
    color: #00ff85;
    text-shadow: 0px 3px 8px #0f0f0f42;
}

.footer {
    background: #0A2129;
    margin-top: 50px;
}

.social {
    width: 100%;
    padding: 40px;
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 0;
    margin-bottom: 0;
}
 
.social-btn {
    display: inline-block;
    width: 90px;
    height: 90px;
    background: #00ff85;
    margin: 10px;
    border-radius: 30%;
    box-shadow: 0 5px 15px -5px #00ff85;
    color: #000000;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-btn .fab {
    line-height: 90px;
    font-size: 26px;
    transition: 0.2s linear;
}

.social-btn:hover .fab {
    transform: scale(1.3);
    color: #f1f1f1;
}

.social-btn::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: #000000;
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}

.social-btn:hover::before {
    animation: aaa 0.7s 1;
    top: -10%;
    left: -10%;
}

a {
    text-decoration: none;
}

.trading-mark {
    font-size: 10px;
    font-weight: 100;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
    color: white;
    padding-bottom: 8px;
}

@keyframes aaa {
    0% {
        left: -110%;
        top: 90%;
    }

    50% {
        left: 10%;
        top: -30%;
    }

    100% {
        top: -10%;
        left: -10%;
    }
}